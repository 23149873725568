// helpers
import LOCALIZATION_REGEX from "@/globals/localization.regex";

function getLocalizationCode(pathname: string): "uk" | "aus" | null {
  const match = pathname.match(LOCALIZATION_REGEX);

  if (match) {
    const locale = match[1].toLowerCase();

    return locale === "aus" ? "aus" : "uk";
  }

  return null;
}

export default getLocalizationCode;
