/* eslint-disable no-useless-catch */
import Axios, { AxiosResponse } from "axios";
import { getCookie, setCookie, deleteCookie } from "cookies-next";
import { redirect } from "next/navigation";

// helpers
import getLocalizationCode from "@/helpers/getLocalizationCode";
import mapWhiteLabelHeader from "@/helpers/mapWhiteLabelHeader";
import API_ROUTES from "./routes.constant";
import { queryClient } from "./query/reactQuery";
import notification from "@/components/notifications";

// interfaces
import { ITokens } from "@/types/api/Tokens";

const isServer = typeof window === "undefined";

const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_API_URL,
  headers: {
    ...mapWhiteLabelHeader(),
  }
});

async function getRefreshToken () {
  if (isServer) {
    const { cookies } = await import("next/headers");

    return getCookie("refreshToken", { cookies });
  }

  return getCookie("refreshToken");
}

const loginUrl = process.env.NEXT_PUBLIC_IS_OPS_DASHBOARD ? "/ops" : "/login";

function clearTokens() {

  deleteCookie("accessToken");
  deleteCookie("refreshToken");

  queryClient.clear();

  window.location.href = loginUrl;
}

async function refreshTokens(refresh: string) {
  const resp = await axios.post<ITokens>(API_ROUTES.account.refreshToken, { refresh }).catch(() => null);

  if (!resp && isServer) {
    redirect(loginUrl);
  } else if (!resp && !isServer && !window.location.pathname.includes(loginUrl)) {
    clearTokens();
  }

  if (!isServer && resp) {
    setCookie("accessToken", resp.data.access, {
      path: "/",
      maxAge: 60 * 60 * 8760,
    });
    setCookie("refreshToken", refresh, {
      path: "/",
      maxAge: 60 * 60 * 8760,
    });

    await queryClient.setQueryData([API_ROUTES.account.isAuthenticated, {}], () => ({
      accessToken: resp.data.access,
      refreshToken: refresh,
    }));
  }

  return resp;
}


axios.interceptors.request.use(
  async config => {
    let accessToken = null;
    let localizationCode = null;

    if (isServer) {
      const { cookies, headers } = await import("next/headers");

      const headersList = headers();
      
      localizationCode = (headersList.get("x-localization") || "").toUpperCase() || null;
      accessToken = getCookie("accessToken", { cookies });
    } else {
      accessToken = getCookie("accessToken");

      const localizationCodeDraft = getLocalizationCode(window.location.pathname);
      localizationCode = localizationCodeDraft ? localizationCodeDraft.toUpperCase() : null;
    }

    if (accessToken && !config.headers.Authorization && config.headers.Authorization !== null) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config.headers["geographic-region"] = localizationCode;

    if (!accessToken && !isServer) {
      const landlordAuthHeader = localStorage.getItem("landlordAuthHeader");

      if (landlordAuthHeader) {
        const { label, value } = JSON.parse(landlordAuthHeader);

        config.headers[label] = value;
      }
    }

    return config;
  }
);

axios.interceptors.response.use(
  resp => resp,
  async error => {
    try {
      const canRefresh = [
        !!error.response,
        error.response && error.response.status === 401,
        !!error.config && !["/account/token", "/account/token/refresh"].includes(error.config.url),
        !!error.config && !error.config.__isRetryRequest
      ].every(Boolean);

      switch (true) {
        case canRefresh: {
          error.config.__isRetryRequest = true;

          const refreshToken = await getRefreshToken();

          if (refreshToken) {
            const resp = await refreshTokens(refreshToken) as AxiosResponse<ITokens>;
    
            error.config.headers.Authorization = `Bearer ${resp.data.access}`;

            return axios(error.config);
          }

          clearTokens()

          return;
        }
        case typeof window === "undefined":
          return Promise.reject(error);
        case !!error.response && error.response.status === 403:
          notification({
            placement: "bottomLeft",
            duration: 5,
            message: "Access forbidden",
            type: "error",
          });

          return Promise.reject(error);
        case !!error.response && error.response.status === 500:
        case !!error.response && error.response.status === 502:
        case !!error.response && error.response.status === 503:
          if (process.env.NODE_ENV !== "development") {
            window.location.href = "/500";
          }
          break;
        default:
          return Promise.reject(error);
      }
    } catch (err) {
      throw err;
    }
  }
)

export default axios;
