"use client";

import { HTMLProps, forwardRef } from "react";
import NextLink, { LinkProps } from "next/link";
import { useParams } from "next/navigation";


const Link = (props: LinkProps & HTMLProps<HTMLAnchorElement>, ref: React.Ref<HTMLAnchorElement>) => {
  const { lang } = useParams();

  return (
    <NextLink
      scroll={true} // need to scroll to the top of the page
      { ...props }
      ref={ref}
      href={!process.env.NEXT_PUBLIC_IS_OPS_DASHBOARD && process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" ? `/${lang}${props.href}` : props.href}
    />
  );
}

export default forwardRef(Link);
