function mapWhiteLabelHeader(): Record<string, string> {
  switch (process.env.NEXT_PUBLIC_APP_WL_CODE) {
    case "WERI":
      return { "whitelabel-client": "URW" };
    default:
      return {};
  }
}

export function mapGeographicRegionHeader() {
  const headers: Record<string, string> = {};

  return headers;
}

export function mapWhiteLabelWSQuery() {
  switch (process.env.NEXT_PUBLIC_APP_WL_CODE) {
    case "WERI":
      return { whitelabel_client: "URW" };
    default:
      return "";
  }
}

export default mapWhiteLabelHeader;
